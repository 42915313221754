<template>
  <div v-if="ownDrawer">
    <v-navigation-drawer
      v-model="ownDrawer"
      :hide-overlay="hideOverlay"
      :permanent="permanent"
      :temporary="temporary"
      :width="$vuetify.breakpoint.smAndDown ? '100%' : width"
      app
      right
    >
      <v-card :disabled="loading" class="py-12 mb-12 ma-0" flat tile>
        <v-btn
          id="navigationCloseBtn"
          absolute
          icon
          right
          style="z-index: 1"
          top
          @click="(ownDrawer = !ownDrawer), $emit('close')"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
        <div
          v-if="ownDrawer"
          :class="{
            'px-6': !$vuetify.breakpoint.smAndDown,
            'px-4': $vuetify.breakpoint.smAndDown,
          }"
          class="mb-12"
        >
          <slot />
        </div>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      default: 450,
    },
    temporary: {
      type: Boolean,
      default: true,
    },
    permanent: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideOverlay: Boolean,
  },
  data() {
    return {
      ownDrawer: false,
      isActive: !!this.value,
    };
  },
  computed: {
    ...mapGetters({
      isUploadingProgress: "getIsUploadingReleases",
    }),
  },
  watch: {
    // drawer: function(value) {
    //   this.ownDrawer = !!value;
    //   this.manageScroll(value);
    // },
    // // this is for navigation drawer to remove the scroll when it open
    // ownDrawer: function(v) {
    //   this.manageScroll(v);
    // },
    ownDrawer(v) {
      this.$emit("input", v);
      this.manageScroll(v);
    },
    value(v) {
      this.ownDrawer = v;
      this.manageScroll(v);
    },
  },
  methods: {
    manageScroll(v) {
      if (v) {
        this.hideScroll();
      } else {
        this.showScroll();
        this.$emit("onClose");
      }
    },
    hideScroll() {
      document.documentElement.classList.add("overflow-y-hidden");
    },
    showScroll() {
      document.documentElement.classList.remove("overflow-y-hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.parent {
  position: relative;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>