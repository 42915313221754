var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ownDrawer ? _c('div', [_c('v-navigation-drawer', {
    attrs: {
      "hide-overlay": _vm.hideOverlay,
      "permanent": _vm.permanent,
      "temporary": _vm.temporary,
      "width": _vm.$vuetify.breakpoint.smAndDown ? '100%' : _vm.width,
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.ownDrawer,
      callback: function callback($$v) {
        _vm.ownDrawer = $$v;
      },
      expression: "ownDrawer"
    }
  }, [_c('v-card', {
    staticClass: "py-12 mb-12 ma-0",
    attrs: {
      "disabled": _vm.loading,
      "flat": "",
      "tile": ""
    }
  }, [_c('v-btn', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "id": "navigationCloseBtn",
      "absolute": "",
      "icon": "",
      "right": "",
      "top": ""
    },
    on: {
      "click": function click($event) {
        _vm.ownDrawer = !_vm.ownDrawer, _vm.$emit('close');
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1), _vm.ownDrawer ? _c('div', {
    staticClass: "mb-12",
    class: {
      'px-6': !_vm.$vuetify.breakpoint.smAndDown,
      'px-4': _vm.$vuetify.breakpoint.smAndDown
    }
  }, [_vm._t("default")], 2) : _vm._e()], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }